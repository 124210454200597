@media print {
    .non-printable { display: none; }

    .printable {
        display: block;
        margin-left: 0;
        width: fit-content;
    }
}
.printable {
    margin-left: 20px;
}
.logo-top-left {
    width: 250px;
}
.small-text {
    font-size: 12px;
}
.smaller-text {
    font-size: 10px;
}
.table-row {
    vertical-align: baseline;
    height: 10px;
    max-height: 10px;
    line-height: 12px;
}
