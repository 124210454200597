.height-adjust {
    min-height: 60vh;
}
.card-body {
    overflow-x: auto;
}
.order-card:hover {
    transform: scale(1.1);
    border-radius: 0;
    border: 0;
    transition: transform 0.2s ease;
}

.order-card {
    box-shadow: 0 4px 6px 0 rgba(22, 22, 26, 0.18);

}
.admin-bar li {
}
/*.small-text {*/
/*    font-size: 14px;*/
/*}*/
