.home-card{
    width: 300px;
    height: auto;
    background-color: #f8f7f7;
}

.card img{
    object-fit: cover;
    height: 180px;
    width: 300px;
}
.course-icon {
    font-size: 100px;
    color: darkslateblue;
}
.height-adjust {
    min-height: 60vh;
}
.paging {
    margin-left: 30%;
}