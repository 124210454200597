.height-adjust {
    min-height: 60vh;
}
.card-header {
    height: 45px;
}
.date-picker-div {
    font-size: 12px;
}
.menu-button {
    font-size: 12px;
    height: 80px;
}
.error-message{
    transition: 0.5s;
    z-index: 500;
    top:-50px;
}
.sum-line {
    padding-right: 80px;
}
@media print {
    .document {
        width: 21cm;
        height: 29.7cm;
        margin: 0 0 0 0;

        /* change the margins as you want them to be. */
    }
    .non-printable { display: none; }
    .printable { display: block; }
}