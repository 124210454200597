.home-card{
    width: 300px;
    height: auto;
    background-color: #f8f7f7;
}
.home-card img{
    object-fit: cover;
    height: 200px;
}
.course-icon {
    font-size: 100px;
    color: darkslateblue;
}
.carousel-item {
    height: 70vh;
}
.carousel-item img {
    object-fit: none;
    display: inline-block;
    overflow: hidden;
    height:100vh;
    width: 100vw;
    display: flex;
    flex-shrink: 0;
    background-color: #33383b;
}